body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
  background: #a5a5a54d;
  z-index: 1;
}
::-webkit-scrollbar-thumb {
  background: rgb(2, 89, 106);
  border-radius: 32px;
  z-index: 1;
}
::-webkit-scrollbar-track {
  background: #f2f2f200;
  z-index: 1;
}
.css-13cymwt-control{
  background-color: #f0f0f0!important;
}

.wrapper {
  /*width:200px;*/
  display: flex;
  padding: 5px;
  justify-content: center;
}


.search-container {
  position: relative;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  white-space: nowrap; 
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Adjust the width as needed */
  max-height: 200px; /* Set maximum height to enable scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
}

.search-results li {
  z-index: 1000;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  width: 100%; /* Make li elements full width */
  box-sizing: border-box; /* Include padding and border in width */
}

.search-results li:last-child {
  border-bottom: none;
}

.search-results li:hover {
  background-color: #f9f9f9;
}


/* PrintNote */
.print-note-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.claim-id {
  margin-bottom: 5px;
  font-weight: bold;
}

.claim-id-label {
  color: red;
}

.conversation-container {
  margin-top: 10px;
}

.conversation-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.message-list {
  margin-bottom: 0;
}

.message-item {
  padding: 5px 10px;
}

.message-bubble {
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 8px;
  max-width: 80%;
}

.message-time {
  font-size: 12px;
  color: #999;
}

.message-content {
  display: block;
}

.customer-message {
  text-align: left;
  background-color: #d4e6f1;
}

.lawyer-message {
  text-align: right;
  background-color: #e2f1d4;
}

