@media print {
    .print-container {
      page-break-inside: avoid;
    }
  
    .avoid-break {
      page-break-inside: avoid;
    }
  
    .signature-section {
      page-break-inside: avoid;
      page-break-after: always;
      margin-top: 200px; 
    }
    .signature-section:not(:first-of-type) {
        margin-top: 200px; 
      }
  
    .page-break {
      page-break-before: always;
    }
  }
  