
.card_details {
    padding: 10px 20px;
  
  }

  input {
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;

  }
  
  .card_container {
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 10px 2px grey;
    padding: 20px;
  }
  
  
  
  .check_card_bottom {
    min-width: 250px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .check_input_card {
    min-width: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .card_bottom {
    width: 100%;
    display: flex;
  
    gap: 10px;
  }
  
  .input_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    

  }
  
  .card_title {
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
  }
  
  .card_input {
    padding: 5px 20px;
    /* min-width: 250px; */
  }
  
  .card_head {
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
  }
  