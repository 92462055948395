/*
-------------------------------------------
Footer Css
---------------------------------------------
*/
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  /* display: flex;
  align-items: flex-end; */
}

footer {
  background-color: #424055;
  /* background-image: url("../../../public/assets/image/footer-bg.jpg"); */
  /* background-repeat: repeat-x;
  height: 589px; */
  background-size: cover;
  color: #a7a7a7;
  font-size: 16px;
}

footer * {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  border: none;
  outline: none;
}

.row {
  padding: 1em 1em;
}

.row.primary {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  align-items: stretch;
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  min-height: 15em;
}

.column_links h3 {
  width: 100%;
  text-align: left;
  color: white;
  font-size: 1.4em;
  white-space: nowrap;
}

.column_links ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.column_links li:not(:first-child) {
  margin-top: 0.8em;
}

.column_links ul li a {
  color: white;
  text-decoration: none;
}

.column_links ul li a:hover {
  color: #2a8ded;
}

.about p {
  color: white;
  text-align: justify;
  line-height: 2;
  margin: 0;
}

.about p {
  color: black;
  text-align: justify;
  line-height: 2;
  margin: 0;
}

input,
button {
  font-size: 1em;
  padding: 0.8em;
  width: 100%;
  /* border-radius: 5px; */
  margin-bottom: 5%;
}

button {
  background-color: #c7940a;
  color: #ffffff;
}

div.social {
  display: flex;
  justify-content: space-around;
  font-size: 2.4em;
  flex-direction: row;
  margin-top: 0.5em;
}

.social i {
  color: #bac6d9;
}

.row_copyright {
  /* padding: 0.5em 9.5em 2.5em 9.5em; */
  background-color: #424055;
  /* margin-top: 225px; */
  padding-bottom:20px;
}

@media only screen and (max-width: 1500px) {
  .row_copyright {
    /* padding: 0.5em 5.5em 2em 7.5em; */
    background-color: #424055;
  }
}

@media only screen and (max-width: 1000px) {
  .row_copyright {
    /* padding: 0.5em 4.5em 2em 4.5em; */
    background-color: #424055;
  }
}

/* @media only screen and (max-width: 580px) {
  .row_copyright{
    padding: 0.5em 4.5em 2em 4.5em;
    background-color: #0d0e12;
  }
} */

.footer-menu {
  float: left;
  margin-top: 10px;
}

.footer-menu a {
  color: #cfd2d6;
  padding: 6px;

  text-decoration: none;
}

.footer-menu a:hover {
  color: #27bcda;
}

.row_copyright p {
  color: #eaeaea;
  font-size: 0.9em;
  text-align: left;
}

.row_copyright a {
  color: white;
  padding-top: 14px;
  padding-right: 17px;
  padding-bottom: 14px;
  padding-left: 17px;
  background: #27282c;
  border-radius: 50%;
  margin-left: 10px;
  text-decoration: none;
}

.row_copyright ul {
  list-style-type: none;
}

@media screen and (max-width: 850px) {
  .row.primary {
    grid-template-columns: 1fr;
  }

  .logo_2 {
    width: 200px;
    height: 100px;
    padding-bottom: 15%;
    margin-top: -10%;
  }
}

.logo_2 {
  width: 200px;
  height: 100px;
  padding-bottom: 15%;
  margin-top: -8%;
}

.column_subscribe h3 {
  color: white;
}

.column_subscribe p {
  color: white;
}

.emailid {
  width: 60%;
}
.emailid input{font-style: italic;}
.emailid input::-ms-input-placeholder { /* Edge 12-18 */
  color: #fff;
}

.emailid input::placeholder {
  color: #fff;
}

.emailid button {
  background-color: #ffffff;
  color: #424055;
  font-family: 'Domaine Semibold Italic';
  border-radius: 100px;
}

.footer_class .flex_url4 {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.footer_class .flex_url4 li {
  margin: 0 0 0 2%;
  width: 20%;
}

.footer_class .flex_url4 p {
  width: 80%;
}

.footer_class .flex_url4 li h3 {
  font-weight: bolder;
  color: white;
}

.footer_class .flex_url4 li ul {
  list-style-type: none;
}

.footer_class .flex_url4 li ul li {
  width: 100%;
  margin-top: 5%;
  margin-left: -10%;
}

.footer_class a {
  text-decoration: none;
  color: #cecece;
  font-size: 17px;
}

.footer_class p {
  font-family: "Poppins";
}

.footer_class a {
  font-family: "Poppins";
}