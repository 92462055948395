.columnPrint {
    float: left;
    width: 25%;
    /* padding: 10px; */
    height: auto; /* Should be removed. Only for demonstration */
  }
  
  .rowPrint:after {
    content: "";
    display: table;
    clear: both;
  }

  .printinvoice_divlisting {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
  }
  .printinvoice_divlistingtextfirst {
    font-weight: bolder;
    flex: 0.7;
    margin-left: 20px;
  }
  .printinvoice_divlistingtextsecond {
    font-weight: bolder;
    flex: 0.3;
    position: absolute;
    right: 50px;
  }
  .printinvoice_paymentdetails {
    border-bottom: 1px solid black;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .printinvoice_headerrightbox {
    display: flex;
    justify-content: space-between;
  }
  .printinvoice_headerrightboxtop {
    right: 30px;
    margin: 10px;
    border: 1px solid;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .printinvoice_header {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
  }